/**
 * Const with params element
 * CTA Button component
 * Display a link as a button
 */
/* Import section */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "../styles/components/ctabutton.scss"

/* Declaration function */
const Ctabutton = ({ idCta, classCta, ctaLabel, ctaLink, otherStyle, extLink, target=true }) => {
  const defaultStyle = "cta-button"

  if(extLink && target) {
    return <a id={idCta} className={`${defaultStyle} ${otherStyle} ${classCta}`} href={ctaLink} target="_blank" rel="noreferrer"><span>{ctaLabel}</span></a>
  }
  else if(extLink) {
    return <a id={idCta} className={`${defaultStyle} ${otherStyle} ${classCta}`} href={ctaLink} rel="noreferrer"><span>{ctaLabel}</span></a>
  }else {
    return <Link id={idCta} className={`${defaultStyle} ${otherStyle} ${classCta}`} to={`${ctaLink}/`}><span>{ctaLabel}</span></Link>
  }
}

/* Function propTypes declaration */
Ctabutton.propTypes = {
  idCta: PropTypes.string,
  classCta: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  otherStyle: PropTypes.string,
  extLink: PropTypes.bool
}

Ctabutton.defaultProps = {
  idCta: "",
  classCta: "",
  ctaLabel: "Click here",
  ctaLink: "/",
  otherStyle: "",
  extLink: false
}

/* Export function */
export default Ctabutton
