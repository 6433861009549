/**
 * Class element
 * Main Nav component
 * Display the main nav in header
 */
/* Import section */
import React, { Component } from "react"
import { Link } from "gatsby"
import ToggleList from "./togglelist"
import "../styles/components/navmain.scss"

/* Declaration class */
class Navmain extends Component {

  render() {
    const { onPress } = this.props
    // let arrSubList1 = [
    //   {to:"/virtual-care/", cta:"How it Works"},
    //   {to:"/virtual-care/quality-care/", cta:"Quality Care"},
    //   {to:"/virtual-care/plans/", cta:"Plans"}
    // ]
    let arrSubList2 = [
      {to:"/faq/", cta:"FAQs"},
      {to:"/learning-center/", cta:"Learning Center"},
      {to:"/medical-records-request/", cta:"Medical Records Request"},
    ]

    return (
      <nav key="mainnav" className="main-nav">
        <ul key="main-list" className="main-list">
          <li><Link to="/" onClick={onPress}>Home</Link></li>
          {/* <ToggleList key="toggle-list-1" className="virtual-care" listTitle="Virtual Care" subList={arrSubList1} /> */}
          <ToggleList key="toggle-list-2" listTitle="Help Center" subList={arrSubList2} />
          <li><Link to="/blog/" onClick={onPress}>Blog</Link></li>
          <li><Link to="/about-us/" onClick={onPress}>About</Link></li>
          <li><Link to="/contact/" onClick={onPress}>Contact</Link></li>
        </ul>
      </nav>
    );
  }
}

export default Navmain

