/**
 * Class element
 * Toggle List component
 * Display a list in sublist accordion
 */
/* Import section */
import React, { Component } from "react"
import { Link } from "gatsby"
import { IconContext } from "react-icons"
import { FiPlus } from "react-icons/fi"
import "../styles/components/navmain.scss"

/* Declaration class */
class Togglelist extends Component {

  constructor() {
    super()
    this.state = {
      isOpen: false
    }
  }

  toggleSublist() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render () {
    const { onPress } = this.props
    const classOpen = this.state.isOpen ? "open" : "close"

    return (
      <li className="sublist">
        <span className={'sublist-title ' + this.props.className}>
          <button onClick={() => this.toggleSublist()} onKeyDown={() => this.toggleSublist()} className={ classOpen }>
            <span>{this.props.listTitle}</span>
            <IconContext.Provider value={{ className: "icon-toggle" }}>
              <span className={ classOpen }>
                <FiPlus />
              </span>
            </IconContext.Provider>
          </button>
        </span>
        <ul key="second-list-1" className={'second-list ' + classOpen }>
          {this.props.subList.map((item, index) =>
            <li key={index}><Link to={item.to} onClick={onPress}>{item.cta}</Link></li>
          )}
        </ul>
      </li>
    )
  }
}

/* Export function */
export default Togglelist
