/**
 * Const with params element
 * SEO component
 * Display SEO information from YOAST with HELMET
 */
 /* Import section */
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import React from "react"

/* Declaration function */
const SEO = ({ title, description, datastructured, articleId, articlePicture, classHtml, classbody, slickslider, noindex  }) => {
  const { site, dataFAQ, dataFAQvc, dataArticle, defaultImage, defaultLogo, defaultThumbnailIntro } = useStaticQuery(query)
  const { pathname } = useLocation()

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    siteLanguage,
  } = site.siteMetadata

  const ogImage = defaultImage.childImageSharp.gatsbyImageData.images.fallback.src
  const imageSeo = articlePicture ? siteUrl+articlePicture.images.fallback.src : siteUrl+ogImage

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: imageSeo,
    url: `${siteUrl}${pathname}`,
    language: siteLanguage
  }

  //DataStructured - Logo
  let schemaLogo = null
  schemaLogo = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://portalconnect.net",
    "logo": siteUrl+defaultLogo.childImageSharp.gatsbyImageData.images.fallback.src
  }

  //DataStructured - Video Intro PC VC
  let schemaVideoIntro = null
  if (datastructured === 'intro') {
    schemaVideoIntro = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "PortalConnect Virtual Care Video - Helping you connect with a doctor online",
      "description": "Take charge of your health through the PortalConnect Virtual Care platform! PortalConnect is for anyone looking to speak to a doctor online, whether you have a primary care doctor or not!",
      "thumbnailUrl": siteUrl+defaultThumbnailIntro.childImageSharp.gatsbyImageData.images.fallback.src,
      "uploadDate": "2020-09-21",
      "contentUrl": "https://portalconnect.net/virtual-care",
      "embedUrl": "https://www.youtube.com/embed/dc9O7ZmmQRY",
      "publisher" : {
        "@type" : "Organization",
        "name" : "PortalConnect by Hello Health Inc.",
        "logo": {
          "@type": "ImageObject",
          "url":siteUrl+defaultLogo.childImageSharp.gatsbyImageData.images.fallback.src
        }
      },
    }
  }

  //DataStructured - Schema FAQ
  let schemaFAQ = null
  var mainEntityFaq = []
  if (datastructured === 'faqs') {
    dataFAQ.nodes.map(item => {
      return mainEntityFaq.push({
        "@type": "Question",
        "name": item.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": item.acfPostFaq.response
        }
      })
    })

    dataFAQvc.nodes.map(item => {
      return mainEntityFaq.push({
        "@type": "Question",
        "name": item.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": item.acfPostFaqVC.responseVc
        }
      })
    })

    schemaFAQ = {
      "@context": "http://schema.org",
      "@type": "FAQPage",
      "mainEntity": mainEntityFaq
    }
  }

  //DataStructured - Schema Article Blog
  let schemaArticle = null
  if (datastructured === 'article') {

    var arrDataArticle= dataArticle.nodes.filter(function(element){
      return element.databaseId === articleId
    })

    arrDataArticle.map(itemArticle => {
      let arrTag = []
      let stringTag = null
      itemArticle.tags.nodes.map(tagname => {
        return arrTag.push(tagname.name)
      })
      stringTag = arrTag.join()

      var typeArticle = (itemArticle.categories.nodes[0].slug === "blog") ? "BlogPosting":"NewsArticle"

      schemaArticle = {
        "@context" : "http://schema.org",
        "@type" : typeArticle,
        "name" : itemArticle.title,
        "author" : {
          "@type" : "Person",
          "name" :   itemArticle.author.node.name
        },
        "keywords": stringTag,
        "datePublished" :  itemArticle.date,
        "image" : siteUrl+articlePicture.images.fallback.src,
        "url" : siteUrl+"/"+itemArticle.categories.nodes[0].slug+"/"+itemArticle.slug+"/",
        "headline" : itemArticle.title,
        "publisher" : {
          "@type" : "Organization",
          "name" : "PortalConnect by Hello Health Inc.",
          "logo": {
            "@type": "ImageObject",
            "url":siteUrl+defaultImage.childImageSharp.gatsbyImageData.images.fallback.src
          }
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://portalconnect.net/"+itemArticle.categories.nodes[0].slug
        },
        "dateModified": itemArticle.modified
      }
      return schemaArticle
    })
  }

  return (
    <Helmet title={seo.title} >
      <html lang={siteLanguage} class={classHtml}/>
      {noindex === 'noindex' && <meta name="robots" content="no index, no follow" />}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {datastructured === 'article' ? (<meta property="og:type" content="article" />) : (<meta property="og:type" content="website"></meta>)}
      <meta property="og:url" content={seo.url}/>
      <meta property="og:title" content={seo.title}/>
      <meta property="og:description" content={seo.description}/>
      <meta property="og:image" content={seo.image}/>
      <meta property="og:locale" content={siteLanguage} />

      <script type="application/ld+json">{JSON.stringify(schemaLogo)}</script>
      {datastructured === 'intro' && <script type="application/ld+json">{JSON.stringify(schemaVideoIntro)}</script>}
      {datastructured === 'faqs' && <script type="application/ld+json">{JSON.stringify(schemaFAQ)}</script>}
      {datastructured === 'article' && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}

      {slickslider === 'slick' &&  <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />}
      {slickslider === 'slick' && <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/>}
      {classbody && <body className={classbody} />}
    </Helmet>
  )
}

/* Function propTypes declaration */
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  datastructured: PropTypes.string,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  datastructured: null
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
        siteUrl
        siteLanguage
      }
    }
    defaultImage: file(relativePath: { eq: "social/og_portalconnect.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    defaultLogo: file(relativePath: { eq: "logos/logo_portalconnect_2020_color_byhh.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    defaultThumbnailIntro: file(relativePath: { eq: "content/desktop_video_visit.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 800)
      }
    }
    dataFAQ: allWpFaq(sort: {fields: id}) {
      nodes {
        acfPostFaq {
          response
        }
        title
      }
    }
    dataFAQvc: allWpFaqvc(sort: {fields: id}) {
      nodes {
        acfPostFaqVC {
          responseVc
        }
        title
      }
    }
    dataArticle: allWpPost(sort: {fields: [date], order:DESC}) {
      nodes {
        databaseId
        title
        slug
        date
        modified
        author {
          node {
            name
          }
        }
        tags {
          nodes {
            name
          }
        }
        categories {
          nodes {
            slug
          }
        }
      }
    }
  }
`

/* Export function */
export default SEO
