/**
 * Class element
 * Header component
 * Display the header
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { FiMenu, FiX } from "react-icons/fi"
import Ctabutton from "./ctabutton"
import Iconlogo from "./iconlogo"
import Logo from "./logo"
import Navcontactinformation from "./navcontactinformation"
import Navmain from "./navmain"
import Iconcontact from "../images/icons/iconcontact.inline.svg"
import Iconlogin from "../images/icons/iconlogin.inline.svg"
import "../styles/components/header.scss"
import {LoginUrl} from "../utils/apputils"

/* Declaration class */
class Header extends Component {

  constructor() {
    super()
    this.state = {
      width: 0,
      showMainNav: false,
      scrollCta: false
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth,
        showMainNav: this.state.showMainNav,
        scrollCta: this.state.scrollCta
      })
    }
  }

  handleScroll = () => {
    if(document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
      this.setState({
        width: window.innerWidth,
        showMainNav: this.state.showMainNav,
        scrollCta: true
      })
    }else{
      this.setState({
        width: window.innerWidth,
        showMainNav: this.state.showMainNav,
        scrollCta: false
      })
    }
  }

  manageBurger(boolStateBurger) {
    this.setState({
      width: window.innerWidth,
      showMainNav: boolStateBurger,
      scrollCta: this.state.scrollCta
    })

    if(boolStateBurger === true && this.state.width <= 480){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'auto';
    }
  }

  handleEvent = () => {
    document.body.style.overflow = 'auto';
  };

  render () {
    const isTablet = this.state.width <= 1112
    const isMobile = this.state.width <= 480
    const classShowNav = this.state.showMainNav ? "open":""
    const classCtaScroll = (this.state.scrollCta && isTablet) ? "scroll-cta":""
    const classMobile = isMobile ? "cta-mobile":""
    const classCtaClosed = (this.state.showMainNav && isMobile) ? "closed-cta":""
    const classCtaSupportMobile = (this.props.className === "header-without-login") ? "closed-cta-support":""

    return (
      <header className={this.props.className}>
        <section className={`section-navigation ${classShowNav}`}>
          <div className="close-nav">
            <button className="button-burger" onClick={() => this.manageBurger(false)} aria-label="Close the menu">
            <IconContext.Provider value={{ className: "icon-burger" }}>
              <i aria-hidden="true"><FiX /></i>
            </IconContext.Provider>
            </button>
          </div>
          <div className="content-navigation">
            <Navmain onPress={this.handleEvent} />
            <div className="cta-navigation">
              <Ctabutton ctaLabel="Get Started" ctaLink="/get-started" otherStyle="white-blue-format getstarted-nav" />
            </div>
            <div className="cta-navigation">
              <Ctabutton ctaLabel="Login" ctaLink={LoginUrl} otherStyle="stroke-format" extLink={true} target={false}/>{/*Urgent Fix - PortalConnect iframe login is not working @author Jithin Kuriakose on 09Nov23*/}
            </div>
            <Navcontactinformation key="nav-contactinfolist" />
            <div className="secondary-logo">
              <Logo />
            </div>
          </div>
        </section>

        <section className="section-fixed-header">
          <div className="fixed-header">
            {!this.state.showMainNav && (
              <div className="menu-burger">
                <button className="button-burger" onClick={() => this.manageBurger(true)} aria-label="Open the menu" >
                  <IconContext.Provider value={{ className: "icon-burger" }}>
                    <i aria-hidden="true"><FiMenu /></i>
                  </IconContext.Provider>
                </button>
              </div>
            )}
            {/* <div className="cta-virtualcare">  07-11-23 hided by mc 
              <Ctabutton idCta="virtualcare-header" ctaLabel="Virtual Care" ctaLink="/virtual-care" otherStyle="blue-leaf-format" />
            </div> */}
            <div className="main-logo">
              <Link to="/">
              {isTablet || isMobile ? (
                <Iconlogo type="main" />
              ) : (
                <Logo type="main" />
              )}
              </Link>
            </div>
            <div className="cta-signup">
              <Ctabutton ctaLabel="Get Started" ctaLink="/get-started" otherStyle="white-blue-format getstarted-header" />
            </div>
          </div>
        </section>

        <section className={`section-cta ${classCtaScroll} ${classMobile} ${classCtaClosed} ${classCtaSupportMobile}`}>
          <div className="cta-elements cta-login-elements">
          <Link to={LoginUrl} aria-label="login"><span>Login</span></Link>{/*Urgent Fix - PortalConnect iframe login is not working @author Jithin Kuriakose on 09Nov23*/}
            <div className="cta-elements-txt">
              <p>Login</p>
            </div>
            <div className="cta-elements-icon">
              <Iconlogin className="svg-icon-elements" />
            </div>
          </div>
          <div className="cta-elements cta-support-elements">
            <Link to="/contact/" aria-label="support"><span>Support</span></Link>
            <div className="cta-elements-txt">
              <p>Support</p>
            </div>
            <div className="cta-elements-icon">
              <Iconcontact className="svg-icon-elements" />
            </div>
          </div>
        </section>
      </header>
    )
  }
}

/* Export class */
export default Header
