/**
 * Const with params and useStaticQuery element
 * Icon Logo component
 * Display an icon of logo
 */
/* Import section */
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

/* Declaration function */
const Iconlogo = ({ type }) => {
  if(type === "main"){
    return <StaticImage src="../images/icons/icon_portalconnect_colors_digital.png" alt="Icon PortalConnect by Hello Health" layout="fixed" width={50} />
  }else {
    return <StaticImage src="../images/icons/icon_portalconnect_white_digital.png" alt="Icon PortalConnect by Hello Health white" layout="fixed" width={50} />
  }
}


/* Export function */
export default Iconlogo
