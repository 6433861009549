/**
 * Const with no params and UseStaticQuery
 * Nav Contact information component
 * Display a nav of contact information: phone and email
 */
/* Import section */
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Iconclock from "../images/icons/iconclock.inline.svg"
import Iconcontactgreen from "../images/icons/iconcontactgreen.inline.svg"
import Iconsend from "../images/icons/iconsend.inline.svg"
import "../styles/components/navcontactinformation.scss"

/* Declaration class */
const Navcontactinformation = () => {
  const data = useStaticQuery(
    graphql`
      query {
        options: wpPage(slug: {eq: "options-website"}) {
          acfPageOptions {
            supportPhone
            supportEmail
            supportHours
          }
        }
      }
    `
  )

  return (
    <ul className="contact-information-list">
      <li className="noclickable-li"><span className="box-icon-info"><Iconclock /></span><span dangerouslySetInnerHTML={{ __html:data.options.acfPageOptions.supportHours}} /></li>
      <li><span className="box-icon-info"><Iconcontactgreen /></span><a href={`tel:+${data.options.acfPageOptions.supportPhone}`}>{data.options.acfPageOptions.supportPhone}</a></li>
      <li><span className="box-icon-info"><Iconsend /></span><a href={`mailto:${data.options.acfPageOptions.supportEmail}`}>{data.options.acfPageOptions.supportEmail}</a></li>
    </ul>
  )
}

export default Navcontactinformation


